<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Blog Post'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Blog Post Create'"
      :title="'Blog Post Create'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <div class="form-create__form-container">
            <div class="form-create__form-top">
              <div class="form-create__form-main">
                <p class="form-create__label">Name</p>
                <div class="input-container">
                  <InputText
                    :onChange="setName"
                    :id="'name'"
                    :label="'name'"
                    :type="'text'"
                    :value="formDataToSend.name"
                    :placeholder="'Name'"
                    :error-messages="validationErrors['name']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">H1</p>
                <div class="input-container">
                  <InputText
                    :set-value="formDataToSend"
                    :id="'h1'"
                    :label="'h1'"
                    :type="'text'"
                    :placeholder="'H1'"
                    :error-messages="validationErrors['h1']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">SEO Name</p>
                <div class="input-container">
                  <InputText
                    :onChange="setSeoName"
                    :id="'seo_name'"
                    :label="'seoName'"
                    :value="formDataToSend.seo_name"
                    :type="'text'"
                    :placeholder="'SEO Name'"
                    :error-messages="validationErrors['seoName']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">Title</p>
                <div class="input-container">
                  <InputText
                    :set-value="formDataToSend"
                    :id="'title'"
                    :label="'title'"
                    :type="'text'"
                    :placeholder="'Title'"
                    :error-messages="validationErrors['title']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">Description</p>
                <div class="input-container">
                  <InputText
                    :set-value="formDataToSend"
                    :id="'description'"
                    :label="'description'"
                    :type="'text'"
                    :placeholder="'Description'"
                    :error-messages="validationErrors['description']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">Preview picture in blog</p>
                <p v-if="imageSize" class="form-create__label">
                  Preview: {{ imageSize.blogPost.preview.width }}:{{
                    imageSize.blogPost.preview.height
                  }}
                </p>
                <div class="input-container">
                  <div class="form-create__images">
                    <div class="form-create__image">
                      <picture-input
                        v-if="formDataToSend.img[0].file == null"
                        :ref="`pictureInput_${0}`"
                        @change="handleChoosePhoto(0)"
                        accept="image/*"
                        size="100"
                        width="220"
                        height="220"
                        :name="`image_${0}`"
                        buttonClass="change-image__button"
                        :customStrings="{ drag: '+', change: 'Change' }"
                      />
                      <img
                        v-if="formDataToSend.img[0].file != null"
                        :id="`blah_${0}`"
                        src="#"
                        class="form-create__image-load"
                        alt="load"
                      />
                      <div
                        @click="handleChangeImage(0)"
                        v-if="formDataToSend.img[0].file != null"
                        class="form-create__change-image"
                      >
                        <RestoreIcon />
                      </div>
                    </div>
                  </div>

                  <Notification :error-messages="validationErrors['annotationImage']" />
                </div>
                <div class="input-container">
                  <MainCheckbox
                    :label="'Show intro image on post page'"
                    :id="'show_img'"
                    :set-value="formDataToSend"
                  />
                </div>
                <p class="form-create__label">Alt for image</p>
                <div class="input-container">
                  <InputText
                    :set-value="formDataToSend"
                    :id="'img_alt'"
                    :label="'img_alt'"
                    :type="'text'"
                    :placeholder="'Alt for image'"
                    :error-messages="validationErrors['imgAlt']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">Title for image</p>
                <div class="input-container">
                  <InputText
                    :set-value="formDataToSend"
                    :id="'img_title'"
                    :label="'img_title'"
                    :type="'text'"
                    :placeholder="'Title for image'"
                    :error-messages="validationErrors['imgTitle']"
                    isBordered
                  />
                </div>
                <p class="form-create__label">Annotation</p>
                <div class="input-container">
                  <InputTextarea
                    :set-value="formDataToSend"
                    :id="'annotation'"
                    :label="'annotation'"
                    :placeholder="''"
                    :error-messages="validationErrors['annotation']"
                    isBordered
                  />
                </div>
              </div>
              <div class="form-create__form-said">
                <p class="form-create__label">Status</p>
                <div class="input-container">
                  <MainSelect
                    :id="'status'"
                    :placeholder="'Choose'"
                    :option-list="blogPostStatusesList"
                    :set-value="formDataToSend"
                    :value="formDataToSend.status"
                    :error-messages="validationErrors['status']"
                  />
                </div>

                <p class="form-create__label">Publish at</p>
                <div class="input-container">
                  <date-picker
                    v-model="formDataToSend.date"
                    type="datetime"
                    :value-type="'DD.MM.YYYY HH:mm:ss'"
                    :show-second="false"
                    :format="'DD.MM.YYYY HH:mm:ss'"
                    :title-format="'DD.MM.YYYY HH:mm:ss'"
                    placeholder="dd.mm.yyy --:--"
                    :error-messages="validationErrors['time']"
                  ></date-picker>
                </div>
              </div>
            </div>
            <div class="form-create__content">
              <p class="form-create__label">Table of content</p>
              <div class="form-create__table-content">
                <div v-for="(item, index) in getTableContent" :key="item.id_block">
                  <div
                    v-if="item.type_id == 1 && item.add_to_list && item.header.length > 0"
                    class="form-create__table-content-item"
                  >
                    {{ index + 1 }}. {{ item.header }}
                  </div>
                </div>
              </div>
              <div class="form-create__content-container">
                <div
                  v-for="(item, index) in formDataToSend.content"
                  :key="item.id_block"
                  class="form-create__content-item"
                >
                  <div class="form-create__content-nav">
                    <div
                      v-if="formDataToSend.content.length > 1 && index > 0"
                      @click="moveContentBlock('up', index)"
                      class="form-create__content-nav-item up"
                    >
                      <SelectIcon />
                    </div>
                    <div
                      v-if="
                        formDataToSend.content.length > 1 &&
                        index != formDataToSend.content.length - 1
                      "
                      @click="moveContentBlock('down', index)"
                      class="form-create__content-nav-item down"
                    >
                      <SelectIcon />
                    </div>
                    <div
                      @click="deleteContentBlock(item.type_id, index)"
                      class="form-create__content-nav-item"
                    >
                      <CancelIcon />
                    </div>
                  </div>
                  <div v-if="item.type_id == 1">
                    <p class="form-create__label">H2</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'header'"
                        :label="'header'"
                        :type="'text'"
                        :placeholder="'H2'"
                        isBordered
                      />
                    </div>
                    <p class="form-create__label">Hyperlink</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'hyperlink'"
                        :label="'hyperlink'"
                        :type="'text'"
                        :placeholder="'Hyperlink'"
                        isBordered
                      />
                    </div>
                    <div class="input-container">
                      <MainCheckbox
                        :label="'Add to list'"
                        :id="'add_to_list'"
                        :set-value="formDataToSend.content[index]"
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 2">
                    <p class="form-create__label">H3</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'header'"
                        :label="'header'"
                        :type="'text'"
                        :placeholder="'H3'"
                        isBordered
                      />
                    </div>
                    <p class="form-create__label">Hyperlink</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'hyperlink'"
                        :label="'hyperlink'"
                        :type="'text'"
                        :placeholder="'Hyperlink'"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 3">
                    <p class="form-create__label">H4</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'header'"
                        :label="'header'"
                        :type="'text'"
                        :placeholder="'H4'"
                        isBordered
                      />
                    </div>
                    <p class="form-create__label">Hyperlink</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'hyperlink'"
                        :label="'hyperlink'"
                        :type="'text'"
                        :placeholder="'Hyperlink'"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 4">
                    <p class="form-create__label">Title</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'header'"
                        :label="'header'"
                        :type="'text'"
                        :placeholder="'Title'"
                        isBordered
                      />
                    </div>
                    <div class="form-create__list-items">
                      <div
                        v-for="(element, i) in formDataToSend.content[index].items"
                        class="form-create__list-item"
                        :key="i"
                      >
                        <p class="form-create__label">List element</p>
                        <div class="input-container">
                          <InputText
                            :set-value="formDataToSend.content[index].items[i]"
                            :id="'item'"
                            :label="'item'"
                            :type="'text'"
                            :placeholder="'List element'"
                            isBordered
                          />
                        </div>
                        <div
                          @click="handleDeleteListItem(index, i)"
                          class="form-create__list-item-delete"
                        >
                          <CancelIcon />
                        </div>
                      </div>
                      <div @click="handleAddListItem(index)" class="form-create__list-item-add">
                        <AddCircle />
                      </div>
                    </div>
                  </div>
                  <div v-if="item.type_id == 5">
                    <p class="form-create__label">Text</p>
                    <div class="input-container">
                      <InputTextarea
                        :set-value="formDataToSend.content[index]"
                        :id="'quote_text'"
                        :label="'quote_text'"
                        :placeholder="''"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 6">
                    <p class="form-create__label">Text</p>
                    <div class="input-container">
                      <TextEditor
                        :set-value="formDataToSend.content[index]"
                        :id="'text'"
                        :placeholder="''"
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 7">
                    <p class="form-create__label">Image</p>
                    <p v-if="imageSize" class="form-create__label">
                      Content: {{ imageSize.blogPostContent.content.width }}:{{
                        imageSize.blogPostContent.content.height
                      }}
                    </p>
                    <div class="input-container">
                      <div class="form-create__images">
                        <div class="form-create__image">
                          <picture-input
                            v-if="formDataToSend.content[index].image_file[0].file == null"
                            :ref="`pictureInput-content_${index}`"
                            @change="handleChoosePhotoContent(index)"
                            accept="image/*"
                            size="100"
                            width="220"
                            height="220"
                            :name="`image-content_${index}`"
                            buttonClass="change-image__button"
                            :customStrings="{ drag: '+', change: 'Change' }"
                          />
                          <img
                            v-if="formDataToSend.content[index].image_file[0].file != null"
                            :id="`blah-content_${index}`"
                            src="#"
                            class="form-create__image-load"
                            alt="load"
                          />
                          <div
                            @click="handleChangeImageContent(index)"
                            v-if="formDataToSend.content[index].image_file[0].file != null"
                            class="form-create__change-image"
                          >
                            <RestoreIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p class="form-create__label">Image Alt</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'image_alt'"
                        :label="'image_alt'"
                        :type="'text'"
                        :placeholder="'Image Alt'"
                        isBordered
                      />
                    </div>
                    <p class="form-create__label">Image Title</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'image_title'"
                        :label="'image_title'"
                        :type="'text'"
                        :placeholder="'Image Title'"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 8">
                    <p class="form-create__label">Youtube link</p>
                    <p>
                      *You need to take this part from the url video youtube:
                      https://www.youtube.com/watch?v=<b>qJHyhhwBt3g</b>
                    </p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'video_link'"
                        :label="'video_link'"
                        :type="'text'"
                        :placeholder="'qJHyhhwBt3g'"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 9">
                    <p class="form-create__label">Button name</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'button_name'"
                        :label="'button_name'"
                        :type="'text'"
                        :placeholder="'Button name'"
                        isBordered
                      />
                    </div>
                    <p class="form-create__label">Button link</p>
                    <div class="input-container">
                      <InputText
                        :set-value="formDataToSend.content[index]"
                        :id="'button_link'"
                        :label="'button_link'"
                        :type="'text'"
                        :placeholder="'Button link'"
                        isBordered
                      />
                    </div>
                  </div>
                  <div v-if="item.type_id == 10">
                    <div class="input-container">
                      <div class="form-create__delimiter"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-create__content-add">
                <div @click="handleAddBlock" class="form-create__content-add-button">
                  <AddCircle class="form-create__content-add-button-icon" />
                </div>
                <div v-if="addBlock" class="form-create__content-add-items">
                  <div @click="addContentBlock(1)" class="form-create__content-add-item">H2</div>
                  <div @click="addContentBlock(2)" class="form-create__content-add-item">H3</div>
                  <div @click="addContentBlock(3)" class="form-create__content-add-item">H4</div>
                  <div @click="addContentBlock(4)" class="form-create__content-add-item">
                    <FormatListIcon />
                  </div>
                  <div @click="addContentBlock(5)" class="form-create__content-add-item">
                    <FormatQuoteIcon />
                  </div>
                  <div @click="addContentBlock(6)" class="form-create__content-add-item">
                    <TextFieldIcon />
                  </div>
                  <div @click="addContentBlock(7)" class="form-create__content-add-item">
                    <FormatImageIcon />
                  </div>
                  <div @click="addContentBlock(8)" class="form-create__content-add-item">
                    <VideoIcon />
                  </div>
                  <div @click="addContentBlock(9)" class="form-create__content-add-item">
                    <ShareIcon />
                  </div>
                  <div @click="addContentBlock(10)" class="form-create__content-add-item">
                    <LineIcon />
                  </div>
                </div>
              </div>
            </div>
            <p class="form-create__label">Blog categories</p>
            <div class="input-container checkbox-container">
              <InputRadio
                :set-value="formDataToSend"
                :id="'category_id'"
                :values="blogCategories"
                :name="'name'"
                :value="'id'"
              />
              <Notification :error-messages="validationErrors['categoryId']" />
            </div>
          </div>
          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
        <MainModal
          v-if="errorModal"
          :onClose="
            () => {
              errorModal = false;
            }
          "
          modalCenter
        >
          <div class="error-img">{{ errorModalText }}</div></MainModal
        >
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import { seoTransform } from "~/helpers/seoTransform";
import blogApi from "~/api/blog";
import PictureInput from "vue-picture-input";
import moment from "moment";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";
import InputRadio from "~/components/atoms/inputs/InputRadio.vue";
import Notification from "~/components/molecules/Notification.vue";
import TextEditor from "~/components/atoms/inputs/TextEditor.vue";
import MainModal from "~/components/molecules/MainModal.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";

import RestoreIcon from "~/assets/images/icons/restotre.svg";
import AddCircle from "~/assets/images/icons/add_circle.svg";
import CancelIcon from "~/assets/images/icons/cancel-icon.svg";
import SelectIcon from "~/assets/images/icons/select-icon-small.svg";
import TextFieldIcon from "~/assets/images/icons/text_fields.svg";
import FormatListIcon from "~/assets/images/icons/format_list_bulleted.svg";
import FormatQuoteIcon from "~/assets/images/icons/format_quote.svg";
import ShareIcon from "~/assets/images/icons/ios_share.svg";
import FormatImageIcon from "~/assets/images/icons/format_image.svg";
import VideoIcon from "~/assets/images/icons/video-icon.svg";
import LineIcon from "~/assets/images/icons/line-icon.svg";

export default {
  name: "BlogPostCreate",
  metaInfo: {
    title: "Blog Post Create",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        h1: "",
        seo_name: "",
        title: "",
        description: "",
        status: false,
        annotation: "",
        category_id: null,
        date: null,
        status: null,
        img: [
          {
            id: 0,
            file: null,
          },
        ],
        imgUrl: [
          {
            url: null,
          },
        ],
        img_alt: "",
        img_title: "",
        show_img: false,
        content: [],
      },
      addBlock: false,
      blogCategories: [],
      errorModal: false,
      errorModalText: "",
      imageSize: null,
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainCheckbox,
    InputTextarea,
    InputRadio,
    Notification,
    PictureInput,
    RestoreIcon,
    AddCircle,
    CancelIcon,
    SelectIcon,
    TextFieldIcon,
    FormatListIcon,
    FormatQuoteIcon,
    ShareIcon,
    FormatImageIcon,
    VideoIcon,
    LineIcon,
    TextEditor,
    MainModal,
    DatePicker,
    MainSelect,
  },
  mounted() {
    this.getBlogCategories();
    this.getImageSizes();
    this.getStatuses();
  },
  computed: {
    ...mapState("blog", ["error", "loadingProcess", "validationErrors", "blogPostStatusesList"]),
    getFirstLink() {
      return ROUTE.BLOG_POSTS_LIST.replace(":page", 1);
    },
    getTableContent() {
      const list = [];
      if (this.formDataToSend.content.length > 0) {
        for (let i = 0; this.formDataToSend.content.length > i; i++) {
          if (
            this.formDataToSend.content[i].type_id == 1 &&
            this.formDataToSend.content[i].add_to_list &&
            this.formDataToSend.content[i].header.length > 0
          ) {
            list.push(this.formDataToSend.content[i]);
          }
        }
      }
      return list;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        seo_name:
          this.formDataToSend.seo_name.length != undefined
            ? this.formDataToSend.seo_name.trim()
            : "",
        h1: this.formDataToSend.h1.trim(),
        title: this.formDataToSend.title.trim(),
        description: this.formDataToSend.description.trim(),
        annotation: this.formDataToSend.annotation.trim(),
        is_show_img: this.formDataToSend.show_img ? 1 : 0,
        annotation_image: this.formDataToSend.imgUrl[0].url,
        title_img: this.formDataToSend.img_title,
        alt: this.formDataToSend.img_alt,
        category_id: this.formDataToSend.category_id,
      };
      if (this.formDataToSend.status != null) {
        data.status_id = this.formDataToSend.status.id;
      }
      if (this.formDataToSend.status != null) {
        if (
          +this.formDataToSend.status.id === 3 &&
          (this.formDataToSend.date == null || this.formDataToSend.date === "Invalid date")
        ) {
          this.$store.commit("blog/setValidationErrors", {
            time: ["Fill in the time field!"],
          });
          return false;
        }
      }
      if (this.formDataToSend.date != null) {
        data.published_at = moment(this.formDataToSend.date, "DD-MM-YYYY HH:mm:ss").format(
          "YYYY-MM-DD HH:mm:ss",
        );
      } else {
        data.published_at = moment().format("YYYY-MM-DD HH:mm:ss");
      }
      const content = {};
      const contentArray = this.formDataToSend.content;
      if (contentArray.length > 0) {
        for (let i = 0; contentArray.length > i; i++) {
          const item = {};

          if (contentArray[i].type_id == 1 && contentArray[i].header.length) {
            item.type = 1;
            item.header = contentArray[i].header;
            if (contentArray[i].hyperlink.length) {
              item.hyperlink = contentArray[i].hyperlink;
            }
            item.add_to_list = contentArray[i].add_to_list ? 1 : 0;
          } else if (contentArray[i].type_id == 2 && contentArray[i].header.length) {
            item.type = 2;
            item.header = contentArray[i].header;
            if (contentArray[i].hyperlink.length) {
              item.hyperlink = contentArray[i].hyperlink;
            }
          } else if (contentArray[i].type_id == 3 && contentArray[i].header.length) {
            item.type = 3;
            item.header = contentArray[i].header;
            if (contentArray[i].hyperlink.length) {
              item.hyperlink = contentArray[i].hyperlink;
            }
          } else if (contentArray[i].type_id == 4 && contentArray[i].items.length) {
            item.type = 4;
            item.header = contentArray[i].header;
            const elements = [];
            for (let x = 0; contentArray[i].items.length > x; x++) {
              if (contentArray[i].items[x].item.length > 0) {
                elements.push(contentArray[i].items[x].item);
              }
            }
            item.items = elements;
          } else if (contentArray[i].type_id == 5 && contentArray[i].quote_text.length) {
            item.type = 5;
            item.quote_text = contentArray[i].quote_text;
          } else if (contentArray[i].type_id == 6 && contentArray[i].text.length) {
            item.type = 6;
            item.text = contentArray[i].text;
          } else if (contentArray[i].type_id == 7 && contentArray[i].image_url[0].url != null) {
            item.type = 7;
            item.image = contentArray[i].image_url[0].url;
            item.image_alt = contentArray[i].image_alt;
            item.image_title = contentArray[i].image_title;
          } else if (contentArray[i].type_id == 8 && contentArray[i].video_link.length) {
            item.type = 8;
            item.video_link = contentArray[i].video_link;
          } else if (contentArray[i].type_id == 9 && contentArray[i].button_name.length) {
            item.type = 9;
            item.button_name = contentArray[i].button_name;
            item.button_link = contentArray[i].button_link;
          } else if (contentArray[i].type_id == 10) {
            item.type = 10;
            item.delimiter = 1;
          }

          content[i + 1] = item;
        }
        data.content = content;
      }

      this.$store.dispatch("blog/createBlogPost", data);
    },
    addContentBlock(id) {
      if (id === 1) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 1,
          header: "",
          hyperlink: "",
          add_to_list: true,
        });
      } else if (id === 2) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 2,
          header: "",
          hyperlink: "",
        });
      } else if (id === 3) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 3,
          header: "",
          hyperlink: "",
        });
      } else if (id === 4) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 4,
          header: "",
          items: [
            {
              item: "",
            },
          ],
        });
      } else if (id === 5) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 5,
          quote_text: "",
        });
      } else if (id === 6) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 6,
          text: "",
        });
      } else if (id === 7) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 7,
          image_file: [
            {
              file: null,
            },
          ],
          image_url: [
            {
              url: null,
            },
          ],
          image_alt: "",
          image_title: "",
        });
      } else if (id === 8) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 8,
          video_link: "",
        });
      } else if (id === 9) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 9,
          button_name: "",
          button_link: "",
        });
      } else if (id === 10) {
        this.formDataToSend.content.push({
          id_block: Math.floor(Math.random() * 100000),
          type_id: 10,
          delimiter: true,
        });
      }
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.BLOG_POSTS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
      this.setSeoName(value);
    },
    setSeoName(data) {
      const value = seoTransform(data);
      this.formDataToSend.seo_name = value;
      this.handleCheckSeoName();
    },
    handleCheckSeoName() {
      if (this.formDataToSend.seo_name.length > 0) {
        const url = `/${this.formDataToSend.seo_name.trim()}`;
        this.$store.dispatch("blog/blogPostCheckSeo", url);
      }
    },
    getBlogCategories() {
      try {
        blogApi.blogCategoriesGetTree().then((res) => {
          this.blogCategories = res.data.items;
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleChoosePhoto(index) {
      setTimeout(() => {
        this.formDataToSend.img[index].file = document.querySelector(
          `input[name="image_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.img[index].file);

        this.$store.commit("blog/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.img[index].file);
        blogApi
          .blogPostImagesUpload(data)
          .then((res) => {
            this.formDataToSend.imgUrl[index].url = res.data.fileName;
          })
          .catch((error) => {
            this.formDataToSend.img[index].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("blog/setLoading", false);
          });
      }, 0);
    },
    handleChangeImage(index) {
      this.$store.commit("blog/setLoading", true);
      const data = {
        fileName: this.formDataToSend.imgUrl[index].url,
      };
      blogApi
        .blogPostImagesDelete(data)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.img[index].file = null;
            this.formDataToSend.imgUrl[index].url = null;
          }
        })
        .catch((error) => {
          this.errorModal = true;
          this.errorModalText = "You can't delete images. Error";
        })
        .finally(() => {
          this.$store.commit("blog/setLoading", false);
        });
    },
    handleAddBlock() {
      this.addBlock = !this.addBlock;
    },
    deleteContentBlock(type_id, index) {
      if (type_id != 7) {
        this.formDataToSend.content.splice(index, 1);
      } else {
        this.handleChangeImageContent(index);
        this.formDataToSend.content.splice(index, 1);
      }
    },
    arrayMove(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },
    moveContentBlock(move, index) {
      if (move === "up") {
        this.formDataToSend.content = this.arrayMove(this.formDataToSend.content, index, index - 1);
      } else if (move === "down") {
        this.formDataToSend.content = this.arrayMove(this.formDataToSend.content, index, index + 1);
      }
    },
    handleAddListItem(index) {
      this.formDataToSend.content[index].items.push({
        item: "",
      });
    },
    handleDeleteListItem(index, i) {
      this.formDataToSend.content[index].items.splice(i, 1);
    },
    handleChoosePhotoContent(index) {
      setTimeout(() => {
        this.formDataToSend.content[index].image_file[0].file = document.querySelector(
          `input[name="image-content_${index}"]`,
        ).files[0];
        const reader = new FileReader();

        reader.onload = function (e) {
          document.getElementById(`blah-content_${index}`).setAttribute("src", e.target.result);
        };

        reader.readAsDataURL(this.formDataToSend.content[index].image_file[0].file);

        this.$store.commit("blog/setLoading", true);
        const data = new FormData();
        data.append("image", this.formDataToSend.content[index].image_file[0].file);
        blogApi
          .blogPostImagesContentUpload(data)
          .then((res) => {
            this.formDataToSend.content[index].image_url[0].url = res.data.fileName;
          })
          .catch((error) => {
            this.formDataToSend.content[index].image_file[0].file = null;
            this.errorModal = true;
            this.errorModalText = "You can't upload images. Error";
          })
          .finally(() => {
            this.$store.commit("blog/setLoading", false);
          });
      }, 0);
    },
    handleChangeImageContent(index) {
      this.$store.commit("blog/setLoading", true);
      const data = {
        fileName: this.formDataToSend.content[index].image_url[0].url,
      };
      blogApi
        .blogPostImagesContentDelete(data)
        .then((res) => {
          if (res.data.deleted) {
            this.formDataToSend.content[index].image_url[0].url = res.data.fileName;
            this.formDataToSend.content[index].image_file[0].file = res.data.fileName;
          }
        })
        .catch((error) => {
          this.errorModal = true;
          this.errorModalText = "You can't delete images. Error";
        })
        .finally(() => {
          this.$store.commit("blog/setLoading", false);
        });
    },
    getImageSizes() {
      blogApi
        .blogPostImageSizes()
        .then((res) => {
          this.imageSize = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStatuses() {
      this.$store.dispatch("blog/getBlogPostStatuses");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.mx-datepicker {
  width: 100%;
}

.form-create {
  &__images {
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    position: relative;
    width: 220px;
    height: 220px;
    margin: 0 30px 45px 0;
    border: 1px solid $mainDisabled;
  }

  &__delete-image {
    position: absolute;
    top: -19px;
    right: -26px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  &__add-image {
    position: absolute;
    top: -19px;
    right: -8px;
    cursor: pointer;
    transform: rotate(45deg);

    svg {
      path {
        fill: $mainSecondColor;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__image-load {
    width: 220px;
    height: 220px;
    object-fit: cover;
  }

  &__content-add-button-icon {
    width: 48px;
    height: 48px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__change-image {
    position: absolute;
    bottom: -40px;
    left: 44%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: $mainColor;
    border-radius: 50px;
    cursor: pointer;

    svg {
      path {
        fill: $g-color-white;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__content-add {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  &__content-add-items {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding: 0 10px;
    height: 48px;
    border: 1px solid $mainDisabled;
    border-radius: 50px;
  }

  &__content-add-item {
    position: relative;
    display: flex;
    color: $mainSecondTextColor;
    font-size: 20px;
    font-weight: bold;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__content-item {
    padding: 15px;
    border: 1px solid $mainDisabled;
    border-radius: 10px;
    margin-top: 20px;
  }

  &__content-nav {
    display: flex;
    justify-content: flex-end;
  }

  &__content-nav-item {
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    &.up {
      position: relative;
      top: -4px;
      transform: rotate(-180deg);
    }
  }

  &__table-content {
    margin-top: 20px;
    min-height: 100px;
    padding: 15px;
    border: 1px solid $mainDisabled;
    border-radius: 10px;
  }

  &__table-content-item {
    padding-bottom: 5px;
  }

  &__list-item {
    position: relative;
  }

  &__list-item-delete {
    position: absolute;
    top: 15px;
    right: -10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__list-item-add {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 36px;
      height: 36px;
    }
  }

  &__delimiter {
    border-bottom: 1px solid $mainColor;
  }
}

.error-img {
  font-size: 24px;
  color: red;
  text-align: center;
  margin: auto;
}
</style>
