<template>
  <div :class="['input-main-select']">
    <label class="input-main-select__label" :for="label">
      <v-select
        :id="label"
        :placeholder="placeholder"
        :options="optionList"
        @input="onChange"
        :value="value"
        :label="labelName"
        :searchable="searchable"
        :disabled="disabled"
      >
      </v-select>
    </label>
    <div v-if="errorMessages.length">
      <p class="input-main-select__error">
        {{ errorMessages }}
      </p>
    </div>
  </div>
</template>

<script>
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "InputMainSelect",
  props: {
    optionList: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: [Object, Number],
      default() {
        return {};
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    labelName: {
      type: String,
      default: "name",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    change: {
      type: [Function, Boolean],
      default: false,
    },
  },
  methods: {
    onChange(value) {
      this.setValue[this.id] = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-main-select {
  color: $mainSecondColor;
  width: 100%;
  padding: 3px;
  border: 1px solid $mainSecondColor;
  border-radius: 5px;

  &__error {
    color: red;
    position: absolute;
    padding-top: 10px;
    font-size: 14px;
  }

  &__label {
    width: 100%;
    margin: 0;
  }
}

/deep/ .vs__dropdown-toggle {
  border: none;
}
/deep/ .vs__dropdown-option--highlight {
  background-color: $mainSecondColor;
}
/deep/ .vs__dropdown-menu {
  padding: 0;
  margin-top: 5px;
}
/deep/ .vs__clear {
  display: none;
}
</style>
