<template>
  <div :class="['textarea-input', isBordered ? 'textarea-input--bordered' : '']">
    <label :for="label">
      <textarea :id="label" v-model="setValue[id]" :placeholder="placeholder" />
    </label>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="textarea-input__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputTextarea",
  props: {
    isBordered: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
<style lang="scss">
.textarea-input {
  label {
    width: 100%;
  }
  width: 100%;
  padding-bottom: 20px;
  textarea {
    position: relative;
    width: 100%;
    background: #e5e5e7;
    height: 120px;
    border: none;
    outline: none;
    font-size: 14px;
    border-radius: 5px;
    padding: 3px 0 16px 16px;
    min-height: 70px;
    resize: none;
    padding: 14px 0 0 15px;
    &::placeholder {
      color: #7d7d7d;
      font-size: 14px;
      letter-spacing: normal;
    }
  }
  &--bordered {
    textarea {
      border: 1px solid #cdcdce;
      box-sizing: border-box;

      &:focus {
        background: #cdcdce;
      }
    }
  }
  &__error {
    position: absolute;
    color: red;
    padding-left: 10px;
    font-size: 12px;
  }
}
</style>
