<template>
  <div :class="['text-editor']">
    <div class="text-editor__tools">
      <div
        @click="
          () => {
            this.editor = true;
          }
        "
        :class="['text-editor__tools-item', editor && 'active']"
      >
        Editor
      </div>
      <div
        @click="
          () => {
            this.editor = false;
          }
        "
        :class="['text-editor__tools-item', !editor && 'active']"
      >
        Text
      </div>
    </div>
    <InputTextarea
      v-if="!editor"
      :set-value="setValue"
      :id="id"
      :label="id"
      :placeholder="''"
      isBordered
    />
    <vue-editor v-if="editor" v-model="setValue[id]" :editorToolbar="customToolbar"></vue-editor>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id" class="text-editor__error">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import InputTextarea from "~/components/atoms/inputs/InputTextarea.vue";

export default {
  name: "TextEditor",
  components: {
    VueEditor,
    InputTextarea,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      editor: true,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: "left" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ color: [] }],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.text-editor {
  &__error {
    position: absolute;
    color: red;
    padding-left: 10px;
    font-size: 12px;
  }

  &__tools {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
  }

  &__tools-item {
    margin-left: 10px;
    padding: 10px 20px;
    background: gainsboro;
    border-radius: 20px;
    font-weight: 700;
    cursor: pointer;

    &.active {
      background: $mainSecondColor;
      color: #fff;
    }
  }
}
</style>
