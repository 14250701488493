<template>
  <div v-show="errorMessages.length">
    <p v-for="error in errorMessages" :key="error.id" class="notification">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style>
.notification {
  color: red;
  padding-left: 10px;
  padding-top: 10px;
  text-align: left;
  font-size: 14px;
}
</style>
