<template>
  <div v-if="values.length > 0" class="radio-input__container">
    <div :class="['radio-input', disabled && 'radio-input--disabled']">
      <div
        @click="setValue[id] = value.id"
        class="radio-input__radio-container"
        v-for="value of values"
        :key="value.id"
      >
        <input
          type="radio"
          :id="value.id"
          v-model="setValue[id]"
          :value="value.name"
          :disabled="disabled"
        />
        <div
          :class="[
            'radio-input__radio',
            setValue[id] === value.id && 'radio-input__radio--checked',
          ]"
        ></div>
        <label :for="value">{{ value[name] }}</label>
      </div>
    </div>
    <div v-if="errorMessages.length">
      <p v-for="error in errorMessages" :key="error.id">
        {{ error }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "InputRadio",
  data() {
    return {};
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    values: {
      type: Array,
      default() {
        return [];
      },
    },
    name: {
      type: String,
      default: "name",
    },
    value: {
      type: String,
      default: "id",
    },
    setValue: {
      type: Object,
      default() {
        return {};
      },
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.radio-input {
  display: flex;
  flex-direction: column;
  input {
    display: none;
  }
  &__container {
    padding-top: 15px;
  }
  &__radio-container {
    display: flex;
    padding-bottom: 15px;

    label {
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  &__radio {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid #131313;
    box-sizing: border-box;
    border-radius: 50px;
    cursor: pointer;
    &--checked {
      background: #fff;
      position: relative;
      display: flex;
      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background: $mainColor;
        margin: auto;
        border-radius: 50px;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &--disabled {
    label {
      color: red;
      margin-left: 20px;
    }
  }
  label {
    margin-left: 20px;
    color: black;
    cursor: pointer;
  }
}
</style>
